var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('title',[_vm._v("Galactiq | Satisfactory")]),_vm._m(0),_c('section',{staticClass:"content-row content-row-gray content-row-color"},[_c('div',{staticClass:"container"},[_c('header',{staticClass:"content-header"},[_c('div',{staticClass:"tab-group tab-group-switch-style"},[_c('ul',[_c('li',{class:_vm.$root.$children[0].period == 1 ? `active` : ``,on:{"click":function($event){return _vm.$root.$children[0].setPeriod(1)}}},[_vm._v(" 1 month ")]),_c('li',{class:_vm.$root.$children[0].period == 3 ? `active` : ``,on:{"click":function($event){return _vm.$root.$children[0].setPeriod(3)}}},[_vm._v(" 3 months ")]),_c('li',{class:_vm.$root.$children[0].period == 6 ? `active` : ``,on:{"click":function($event){return _vm.$root.$children[0].setPeriod(6)}}},[_vm._v(" 6 months ")]),_c('li',{class:_vm.$root.$children[0].period == 12 ? `active` : ``,on:{"click":function($event){return _vm.$root.$children[0].setPeriod(12)}}},[_vm._v(" 12 months ")])])])]),_c('div',{staticClass:"column-row align-center-bottom"},[_c('div',{staticClass:"column-33"},[_c('div',{staticClass:"product-box"},[_vm._m(1),_c('div',{staticClass:"product-price"},[_vm._v(" $"+_vm._s(_vm.$root.$children[0].floatPriceToString(10.99))),_c('span',{staticClass:"term"},[_vm._v("/ month")])]),_vm._m(2),_vm._m(3)])]),_c('div',{staticClass:"column-33"},[_c('div',{staticClass:"product-box"},[_vm._m(4),_c('div',{staticClass:"product-price"},[_vm._v(" $"+_vm._s(_vm.$root.$children[0].floatPriceToString(18.49))),_c('span',{staticClass:"term"},[_vm._v("/ month")])]),_vm._m(5),_vm._m(6)])]),_c('div',{staticClass:"column-33"},[_c('div',{staticClass:"product-box"},[_vm._m(7),_c('div',{staticClass:"product-price"},[_vm._v(" $"+_vm._s(_vm.$root.$children[0].floatPriceToString(21.99))),_c('span',{staticClass:"term"},[_vm._v("/ month")])]),_vm._m(8),_vm._m(9)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content-row content-row-color content-gamebanner"},[_c('div',{staticClass:"container"},[_c('header',{staticClass:"content-header content-header-large content-header-uppercase"},[_c('h1',[_c('mark',[_vm._v("Satisfactory")])]),_c('p',[_vm._v(" Satisfactory is a factory simulation game created by Swedish video game developer Coffee Stain Studios ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-header"},[_c('img',{attrs:{"src":"https://cdn.galactiq.net/images/game-icons/satisfactory/satisfactory-iron.png","alt":"","height":"64px"}}),_c('h4',{staticStyle:{"margin-top":"20px"}},[_vm._v(" Iron ")]),_c('p',[_vm._v("Satisfactory Iron"),_c('br')]),_c('small',[_c('a',{attrs:{"href":"/network"}},[_vm._v("View Locations")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-features"},[_c('ul',[_c('li',[_c('strong',[_vm._v("4")]),_vm._v(" CPU cores")]),_c('li',[_c('strong',[_vm._v("6 GB")]),_vm._v(" RAM")]),_c('li',[_c('strong',[_vm._v("∞ GB")]),_vm._v(" Disk space")])]),_c('ul')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-order"},[_c('a',{staticClass:"button button-secondary",attrs:{"href":"https://console.galactiq.net","target":"_blank"}},[_c('i',{staticClass:"fas fa-shopping-cart icon-left"}),_vm._v("Order Now ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-header"},[_c('img',{attrs:{"src":"https://cdn.galactiq.net/images/game-icons/satisfactory/satisfactory-steel.png","alt":"","height":"64px"}}),_c('h4',{staticStyle:{"margin-top":"20px"}},[_vm._v(" Steel ")]),_c('p',[_vm._v("Satisfactory Steel"),_c('br')]),_c('small',[_c('a',{attrs:{"href":"/network"}},[_vm._v("View Locations")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-features"},[_c('ul',[_c('li',[_c('strong',[_vm._v("4")]),_vm._v(" CPU cores")]),_c('li',[_c('strong',[_vm._v("10 GB")]),_vm._v(" RAM")]),_c('li',[_c('strong',[_vm._v("∞ GB")]),_vm._v(" Disk space")])]),_c('ul')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-order"},[_c('a',{staticClass:"button button-secondary",attrs:{"href":"https://console.galactiq.net","target":"_blank"}},[_c('i',{staticClass:"fas fa-shopping-cart icon-left"}),_vm._v("Order Now ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-header"},[_c('img',{attrs:{"src":"https://cdn.galactiq.net/images/game-icons/satisfactory/satisfactory-caterium.png","alt":"","height":"64px"}}),_c('h4',{staticStyle:{"margin-top":"20px"}},[_vm._v(" Caterium ")]),_c('p',[_vm._v("Satisfactory Caterium"),_c('br')]),_c('small',[_c('a',{attrs:{"href":"/network"}},[_vm._v("View Locations")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-features"},[_c('ul',[_c('li',[_c('strong',[_vm._v("4")]),_vm._v(" CPU cores")]),_c('li',[_c('strong',[_vm._v("12 GB")]),_vm._v(" RAM")]),_c('li',[_c('strong',[_vm._v("∞ GB")]),_vm._v(" Disk space")])]),_c('ul')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-order"},[_c('a',{staticClass:"button button-secondary",attrs:{"href":"https://console.galactiq.net","target":"_blank"}},[_c('i',{staticClass:"fas fa-shopping-cart icon-left"}),_vm._v("Order Now ")])])
}]

export { render, staticRenderFns }